import { useState } from 'react';

/**
 * @deprecated use `useTransition` instead
 * 例: https://github.com/sheinc/she_webapp/blob/53e692ddb615e611207b010b02af47620010210b/frontend-next/app/(without_layout)/courses/%5BcourseId%5D/lessons/%5BlessonId%5D/sections/%5BsectionId%5D/_components/SectionMainPane/UnderstandingCheck/UnderstandingCheck.tsx#L40
 */
export const useSubmitting = <T>(process: () => Promise<T>) => {
  const [submitting, setSubmitting] = useState(false);

  const submit = async () => {
    if (submitting) return;
    try {
      setSubmitting(true);
      return await process();
    } finally {
      setSubmitting(false);
    }
  };

  return { submitting, submit };
};
